import React, { useState, useMemo, useCallback, useRef } from "react";
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    Stack,
    Typography,
    Tooltip, Snackbar,
    Alert,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
    CloseOutlined,
    DeleteOutlineOutlined,
    Group, Groups,
    WarningAmberOutlined,
} from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import * as EmailValidator from "email-validator";


// Project imports
import {
    openShareLabelsDialog,
} from "../../store/reducers/dialogSlice";
import {
    shareGroups,
    changeContacts,
    unshareList,
    setUnShareLabelStatus,
    setGroupsToShare
} from "../../store/reducers/groupsSlice";
import { getSubscriptionData } from "../../store/reducers/userSlice";
import { getSharedUsers } from "../../services/getSharedUsers";
import { ShareLabelIcon } from "../Contacts/ShareLabelIcon";
import { StyledDataGrid } from "../StyledDataGrid";
import VirtualizedAutocomplete from "./VirtualizedAutocomplete";
import PermissionsSelect from "./PermissionsSelect";
import PermissionsSelectForOneUser from "./PermissionsSelectForOneUser";
import NoRowsOverlay from "./NoRowsOverlay";
import LicenseMessage from "./LicenseMessage";
import { getSyncStatusText, getSyncStatusIcon } from "./syncStatusHelpers";
import { UserAvatar } from "../UserAvatar";
import store from "../../store/store";
import { showNotification } from "../../store/reducers/notificationSlice";
import NotificationBar from "../Alerts/NotificationBar";

export default function ShareLabelDialog() {
    const dispatch = useDispatch();
    const open = useSelector((state) => state.dialogsOpen.shareLabels.value);
    const currentGroup = useSelector((state) => state.groups.currentGroup);
    const shareableUsers = useSelector((state) => state.user.shareableUsers);
    const user = useSelector((state) => state.user.user);
    const subscriptionInitialized = useSelector(
        (state) => state.user.subscriptionInitialized
    );
    const subscription = useSelector((state) => state.user.subscription);
    const contacts = useSelector((state) => state.contacts.contacts);
    const shareLabelStatus = useSelector((state) => state.groups.shareLabelStatus);
    const groupsToShare = useSelector((state) => state.groups.groupsToShare);
    const [rowHover, setRowHover] = useState("");
    const [manualDisableValue, setManualDisableValue] = useState(false);
    const [noLicensedEmailValues, setNoLicensedEmailValues] = useState([]);
    const [textValue, setTextValue] = useState("");
    const [emailsValues, setEmailsValues] = useState([]);
    const [emailError, setEmailError] = useState(false);
    const [permission, setPermission] = useState("VIEW");
    const [notify, setNotify] = useState(false);
    const availableSeats = subscription.quantity - subscription.active_quantity;
    const loading = shareLabelStatus === "loading";
    const handleOnMouseEnter = useCallback((event) => {
        setRowHover(event.currentTarget.dataset.id);
    }, []);
    const handleOnMouseLeave = useCallback(() => {
        setRowHover(null);
    }, []);
    const handleClickUnshare = useCallback(
        async (event, id) => {
            event.stopPropagation();
            if (groupsToShare.length === 0) return;
            await dispatch(
                unshareList({ resources: [groupsToShare[0].resource], emails: [id] })
            );
            await dispatch(getSubscriptionData());
            dispatch(setUnShareLabelStatus("succeeded"));
        },
        [dispatch, groupsToShare]
    );
    const group = groupsToShare.length !== 0 ? groupsToShare[0] : null;
    const rows = useMemo(
        () => getSharedUsers(group, user, contacts, shareableUsers),
        [group, user, contacts, shareableUsers]
    );
    const columns = useMemo(
        () => [
            {
                field: "name",
                headerName: "Name and email",
                flex: 1,
                renderCell: (params) => getName(params),
            },
            {
                field: "sync_status",
                headerName: "Status",
                width: 220,
                renderCell: (params) => {
                    if (params.row.shareType === "GROUP") {
                        const group = groupsToShare[0];
                        const usersInDomainGroup = group.share.filter(
                            (user) => user.domain_group_email === params.id
                        );
                        const usersInDomainGroupCount =
                            usersInDomainGroup.length || params.row.count || 0;
                        const usersWithStatusOK = usersInDomainGroup.filter(
                            (user) => user.sync_status === "OK"
                        );
                        const groupSyncStatus =
                            usersInDomainGroupCount === usersWithStatusOK.length
                                ? "Shared"
                                : `Sharing ${usersWithStatusOK.length}/${usersInDomainGroupCount}`;
                        return (
                            <Stack
                                direction={"row"}
                                alignItems="center"
                                justifyContent="center"
                                spacing={1}
                            >
                                {getSyncStatusIcon(
                                    groupSyncStatus === "Shared" ? "OK" : "ON_QUEUE"
                                )}
                                <Typography
                                    sx={{
                                        color: "rgba(32, 33, 36, 1)",
                                        fontSize: 14,
                                        fontFamily: "Open Sans",
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        lineHeight: "24px",
                                        letterSpacing: "0",
                                    }}
                                >
                                    {groupSyncStatus}
                                </Typography>
                            </Stack>
                        );
                    }
                    return (
                        <Stack
                            direction={"row"}
                            alignItems="center"
                            justifyContent="center"
                            spacing={1}
                        >
                            {getSyncStatusIcon(params.value)}
                            <Typography
                                sx={{
                                    color: "rgba(32, 33, 36, 1)",
                                    fontSize: 14,
                                    fontFamily: "Open Sans",
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    lineHeight: "24px",
                                    letterSpacing: "0",
                                }}
                            >
                                {getSyncStatusText(params.value)}
                            </Typography>
                        </Stack>
                    );
                },
            },
            {
                field: "permission",
                headerName: "Permissions",
                width: 150,
                renderCell: (params) => (
                    <>
                        {groupsToShare.length === 1 && (
                            <PermissionsSelectForOneUser
                                user={params.row}
                                groupsToShare={groupsToShare}
                            />
                        )}
                    </>
                ),
            },
            {
                field: "action",
                headerName: "",
                width: 50,
                sortable: false,
                renderCell: (params) => {
                    return params.id === rowHover && params.row.role !== "owner" ? (
                        <Tooltip placement="right" title="Unshare">
                            <span>
                                <IconButton
                                    onClick={(event) => handleClickUnshare(event, params.id)}
                                >
                                    <DeleteOutlineOutlined
                                        sx={{
                                            width: 24,
                                            height: 24,
                                            flexShrink: 0,
                                        }}
                                    />
                                </IconButton>
                            </span>
                        </Tooltip>
                    ) : null;
                },
            },
        ],
        [groupsToShare, rowHover, handleClickUnshare]
    );
    const handleClose = () => {
        setEmailError(false);
        setTextValue("");
        setEmailsValues([]);
        setManualDisableValue(false);
        setNoLicensedEmailValues([]);
        setPermission("VIEW");
        dispatch(setGroupsToShare({ groups: [] }));
        dispatch(openShareLabelsDialog(false));
        setSnackbarOpen(false); // Close the Snackbar when the dialog closes
    };
    const [snackbarOpen, setSnackbarOpen] = useState(false);// Snack bar management
    const dialogRef = useRef(null); // Link to the dialog container
    const handleSnackbarOpen = () => setSnackbarOpen(true);
    const handleSnackbarClose = () => setSnackbarOpen(false);
    const onInputChange = (newInputValue) => {
        setTextValue(newInputValue);
        // Check for empty value
        if (newInputValue.trim() === "") {
            setEmailError(false);
            setNoLicensedEmailValues([]);
            return;
        }
        setEmailError(false);
        const licensedUser = subscription.licensed_users.find(
            (el) => el.email === newInputValue
        );
        // Logic for handling unlicensed users
        const isNotLicensedUser = !licensedUser;
        const isSubscriptionNoAdmin = subscription.status === 2 && subscription.plan_id !== 1;
        const isSubscriptionInactive = subscription.status === 0;
        const isNoAvailableSeats = subscription.plan_id !== 1 && availableSeats === 0;
        setNoLicensedEmailValues(isNotLicensedUser ? [{ email: newInputValue }] : []);
        setManualDisableValue(isNotLicensedUser && (isSubscriptionNoAdmin || isSubscriptionInactive || isNoAvailableSeats));

        if (isNotLicensedUser) {
            if (isSubscriptionNoAdmin) console.log('isSubscriptionNoAdmin');
            if (isNoAvailableSeats) console.log('isNoAvailableSeats');
            if (isSubscriptionInactive) console.log('isSubscriptionInactive');
        }
    };

    const onChange = (event, values) => {
        const preparedValues = values.reduce((acc, item) => {
            if (item.email) {
                return [...acc, item];
            }
            if (typeof item === 'string' && item.includes(' ')) {
                const emails = item
                    .split(' ')
                    .filter(subItem => subItem.trim() !== '') // Exclude lines containing only spaces
                    .map(subItem => ({ email: subItem }));
                return [...acc, ...emails];
            }
            return [...acc, { email: item }];
        }, []);

        const uniqueValues = preparedValues.filter(
            (obj, idx, arr) =>
                idx === arr.findIndex((t) => t.email === obj.email)
        );
        const noLicensedUsers = [];
        const newValue = uniqueValues.map((option) => {
            const newOption = { ...option };
            let error = false;
            if (!EmailValidator.validate(newOption.email)) {
                error = true;
            } else {
                let contact = subscription.licensed_users.find(
                    (el) => el.email === newOption.email
                );
                if (!contact) {
                    contact = shareableUsers.users.find(
                        (el) => el.email === newOption.email && el.domainUser
                    );
                    if (!contact) {
                        if (!newOption.group) {
                            noLicensedUsers.push(newOption.email);
                        }
                    }
                }
            }
            newOption.error = error;
            return newOption;
        });
        const hasError = newValue.some(item => item.error === true);
        setEmailError(hasError);
        setNoLicensedEmailValues(noLicensedUsers);
        setEmailsValues(newValue);
        if (
            noLicensedUsers.length > 0 &&
            subscription.status === 2 &&
            subscription.plan_id !== 1
        ) {
            setManualDisableValue(true);
        } else {
            setManualDisableValue(false);
        }
        if (subscription.status === 0) {
            setManualDisableValue(true);
        }
        if (
            subscription.plan_id !== 1 &&
            noLicensedUsers.length > availableSeats
        ) {
            setManualDisableValue(true);
        }
    };
    const isShareDisabled = () => {
        return !(
            (textValue.length > 0 || emailsValues.length > 0) &&
            subscriptionInitialized &&
            !manualDisableValue
        );
    };
    const handleClickShare = async () => {
        let hasError = false;
        if (textValue.length > 0) {
            if (textValue.includes(' ')) {
                const emailsArray = textValue.split(' ').filter(email => email.trim() !== '');
                emailsArray.forEach(email => {
                    if (!emailsValues.find(value => value.email === email)) {
                        emailsValues.push({ email });
                    }
                });
            }

            if (!textValue.includes(' ') && !emailsValues.find(value => value.email === textValue)) {
                emailsValues.push({ email: textValue });
            }

            const performedValues = emailsValues.map((item) =>
                item.email ? item : { email: item }
            );
            const uniqueValues = performedValues.filter(
                (obj, idx, arr) =>
                    idx === arr.findIndex((t) => t.email === obj.email)
            );
            const newValue = uniqueValues.map((option) => {
                const newOption = option;
                let error = false;
                if (!EmailValidator.validate(newOption.email)) {
                    error = true;
                    hasError = true;
                    setEmailError(true);
                }
                newOption.error = error;
                return newOption;
            });
            setEmailsValues(newValue);
            setTextValue("");
        } else {
            const uniqueValues = emailsValues.filter(
                (obj, idx, arr) =>
                    idx === arr.findIndex((t) => t.email === obj.email)
            );
            const newValue = uniqueValues.map((option) => {
                const newOption = option;
                let error = false;
                if (!EmailValidator.validate(newOption.email)) {
                    error = true;
                    hasError = true;
                    setEmailError(true);
                }
                newOption.error = error;
                return newOption;
            });
            setEmailsValues(newValue);
            setTextValue("");
        }
        if (emailsValues.length) {
            const emailsWithoutErrors = emailsValues.reduce((acc, el) => {
                if (!el.error) {
                    acc.push(el.email);
                }
                return acc;
            }, []);
            const payload = {
                emails: emailsWithoutErrors,
                resources: groupsToShare.map((el) => el.resource),
                notify,
                role: permission,
            };
            const emailsWithErrors = emailsValues.reduce((acc, el) => {
                if (el.error) {
                    acc.push(el);
                }
                return acc;
            }, [])
            setEmailsValues(emailsWithErrors)
            if (emailsWithErrors.length) {
                handleSnackbarOpen();
            }
            if (emailsWithoutErrors.length) {
                await dispatch(shareGroups(payload));
                await dispatch(getSubscriptionData());
                dispatch(changeContacts({ resource: currentGroup.resource }));
            }
        }
    };
    const handleChangeNotify = (event) => {
        setNotify(event.target.checked);
    };
    const filterOptions = (options, { inputValue }) => {
        const loweredInputValue = inputValue.toLowerCase();
        return options.filter(
            (option) =>
                (option.name &&
                    option.name.toLowerCase().includes(loweredInputValue)) ||
                (option.email &&
                    option.email.toLowerCase().includes(loweredInputValue))
        );
    };
    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                ref={dialogRef}
                id="ShareLabelDialog"
                PaperProps={{
                    sx: {
                        width: 840,
                        maxWidth: 840,
                        minWidth: 444,
                        height:
                            (groupsToShare.length === 1 && "auto") ||
                            (groupsToShare.length > 1 && "287"),
                        backgroundColor: "rgba(59, 125, 237, 1)",
                    },
                }}
            >
                <DialogTitle sx={{ backgroundColor: "rgba(59, 125, 237, 1)" }}>
                    <Stack spacing={0}>
                        <Grid container sx={{ alignItems: "center" }}>
                            <Grid item>
                                <Typography
                                    variant={"dialogTitle"}
                                    color={"rgba(255, 255, 255, 1)"}
                                >
                                    {groupsToShare.length === 1
                                        ? "Share label"
                                        : "Share group of labels"}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Box>
                            <Typography
                                noWrap
                                sx={{
                                    color: "rgba(255, 255, 255, 1)",
                                    fontSize: 16,
                                    fontFamily: "Roboto",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "24px",
                                    letterSpacing: "0.2px",
                                    marginBottom: 1,
                                }}
                            >
                                Invite collaborators to access{" "}
                                {groupsToShare.length === 1
                                    ? `the label "${groupsToShare[0].name}"`
                                    : "selected group of labels"}
                            </Typography>
                        </Box>
                    </Stack>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 12,
                        top: 12,
                        color: "rgba(255, 255, 255, 0.54)",
                        "&:hover": {
                            color: "rgba(255, 255, 255, 0.87)",
                            backgroundColor: "rgba(0, 0, 0, 0.16)",
                        },
                    }}
                >
                    <CloseOutlined />
                </IconButton>
                <DialogContent
                    dividers
                    sx={{ border: 0, backgroundColor: "rgba(255, 255, 255, 1)" }}
                >
                    <Stack spacing={1} sx={{ marginTop: "15px" }}>
                        <Stack direction={"row"} spacing={3} sx={{ alignItems: "top" }}>
                            <Box sx={{ width: 516 }}>
                                <VirtualizedAutocomplete
                                    options={shareableUsers.users}
                                    setEmailError={setEmailError}
                                    emailError={emailError}
                                    onChange={onChange}
                                    emailsValues={emailsValues}
                                    textValue={textValue}
                                    setTextValue={setTextValue}
                                    setEmailsValues={setEmailsValues}
                                    onInputChange={(event, newInputValue) =>
                                        onInputChange(newInputValue)
                                    }
                                    filterOptions={filterOptions}
                                    handleClickShare={handleClickShare}
                                />
                            </Box>
                            <Stack
                                direction={"row"}
                                spacing={3}
                                sx={{ height: 40, alignItems: "center" }}
                            >
                                <PermissionsSelect
                                    permission={permission}
                                    setPermission={setPermission}
                                />
                                <LoadingButton
                                    loading={loading}
                                    onClick={handleClickShare}
                                    variant="contained"
                                    disabled={isShareDisabled()}
                                    loadingPosition="start"
                                    sx={{
                                        width: 131,
                                        minWidth: 131,
                                        height: 36,
                                        background: "rgba(59, 125, 237, 1)",
                                        "&.Mui-disabled": {
                                            background: "rgba(0, 0, 0, 0.08)",
                                        },
                                    }}
                                    startIcon={<ShareLabelIcon isShareDisabled={isShareDisabled()} />}
                                >
                                    <Typography
                                        variant={"button"}
                                        sx={{
                                            textTransform: "none",
                                            color: isShareDisabled()
                                                ? "rgba(0, 0, 0, 0.32)"
                                                : "#FFF",
                                            letterSpacing: 0,
                                        }}
                                    >
                                        {loading ? 'Sharing...' : 'Share label'}
                                    </Typography>
                                </LoadingButton>
                            </Stack>
                        </Stack>
                        <Stack paddingLeft={1}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={notify}
                                        onChange={handleChangeNotify}
                                        name="notify"
                                        color="primary"
                                        sx={{ width: 40, height: 40 }}
                                    />
                                }
                                sx={{
                                    width: 200,
                                    height: 40,
                                    ".MuiFormControlLabel-label": {
                                        color: "rgba(0, 0, 0, 0.87)",
                                        fontSize: 14,
                                        fontFamily: "Roboto",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "20px",
                                        letterSpacing: "0.15px",
                                    },
                                }}
                                label="Notify people by email"
                            />
                            <LicenseMessage
                                subscription={subscription}
                                subscriptionInitialized={subscriptionInitialized}
                                noLicensedEmailValues={noLicensedEmailValues}
                                availableSeats={availableSeats}
                            />
                        </Stack>
                        {groupsToShare.length === 1 && (
                            <Grid item md={12}>
                                <div
                                    style={{
                                        width: "100%",
                                        height: "calc(100vh - 540px)",
                                    }}
                                >
                                    <StyledDataGrid
                                        sx={{
                                            "& .MuiDataGrid-row": {
                                                height: 50, // Row height
                                                '&:not(:last-child)': {
                                                    marginBottom: '6px' // Space between rows
                                                },
                                                '&:first-child': {
                                                    marginTop: '8px', // Set the top margin for the first row
                                                },
                                            },
                                            '& .MuiDataGrid-cell[data-field="action"]': {
                                                padding: '0px', // We remove the indents only in the fourth column
                                            },
                                        }}
                                        disableRowSelectionOnClick
                                        rows={rows}
                                        columns={columns}
                                        autoPageSize
                                        rowHeight={50}
                                        slotProps={{
                                            row: {
                                                onMouseEnter: handleOnMouseEnter,
                                                onMouseLeave: handleOnMouseLeave,
                                            },
                                        }}
                                        components={{ NoRowsOverlay }}
                                    />
                                </div>
                            </Grid>
                        )}
                    </Stack>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={3000}
                        onClose={handleSnackbarClose}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        sx={{
                            position: 'absolute',
                            left: '50%',
                            transform: 'translateX(-50%)',
                        }}
                    >
                        <Alert onClose={handleSnackbarClose} severity="error" icon={<WarningAmberOutlined sx={{ width: 22, height: 22 }} />}>
                            <Typography
                                sx={{
                                    color: '#541313',
                                    fontWeight: 500,
                                    fontSize: 14,
                                    fontFamily: 'Roboto',
                                    fontStyle: 'normal',
                                    lineHeight: '143%',
                                    letterSpacing: '0.15px',
                                }}
                            >
                                Some emails are not valid
                            </Typography>
                        </Alert>
                    </Snackbar>
                </DialogContent>
                <DialogActions sx={{ backgroundColor: "rgba(255, 255, 255, 1)" }}>
                    <Button onClick={handleClose}>
                        <Typography
                            variant={"dialogButton"}
                            color={"rgba(0, 0, 0, 0.54)"}
                        >
                            Close
                        </Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

function getName(params) {
    return (
        <Stack
            direction={"row"}
            spacing={1}
            sx={{ width: "100%", alignItems: "center" }}
        >
            {params.row.shareType === "GROUP" ? (
                <Avatar sx={{ background: '#E8F0FE' }}>
                    <Groups sx={{ color: '#3B7DED' }} />
                </Avatar>
            ) : (
                <UserAvatar alt={params.value || params.row.email} src={params.row.photo} sx={{ width: 40, height: 40 }} />
            )}
            <Stack>
                <Typography
                    sx={{
                        color: "rgba(0, 0, 0, 0.87)",
                        fontSize: 14,
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "20px",
                        letterSpacing: "0.2px",
                    }}
                >
                    {params.value || params.row.email}
                </Typography>
                {params.value && (
                    <Typography
                        sx={{
                            color: "rgba(0, 0, 0, 0.6)",
                            fontSize: 13,
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "18px",
                            letterSpacing: "0.2px",
                        }}
                    >
                        {params.row.email}
                    </Typography>
                )}
            </Stack>
        </Stack>
    );
}
